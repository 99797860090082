/**
* Template Name: FlexStart
* Updated: Jun 04 2023 with Bootstrap v5.3.0
* Template URL: https://bootstrapmade.com/flexstart-bootstrap-startup-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
:root {
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: #4154f1;
  text-decoration: none;
}

a:hover {
  color: #717ff5;
  text-decoration: none;
}

h1,
h2,
h4,
h5,
h6 {
  font-family: "Nunito", sans-serif;
}


h3 {
  /* color: #444444; */
  color: #012970;
  font-weight: 500;
  font-size: 18px;
}

h4 {
  /* color: #444444; */
  color: #012970;
  /* font-weight: 500; */

}


/*--------------------------------------------------------------
# Sections
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-header {
  text-align: center;
  padding-bottom: 40px;
}

.section-bg {
  background-color: #f8fbfe;
}

.section-header h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  margin: 0;
  color: #4154f1;
  text-transform: uppercase;
}

.section-header p {
  margin: 10px 0 0 0;
  padding: 0;
  font-size: 38px;
  line-height: 42px;
  font-weight: 700;
  color: #012970;
}



.section-header p::after {
  content: "";
  position: relative;
  display: block;
  width: 115px;
  height: 3px;
  margin-top: 19px;
  background: #5969f3;
  bottom: 0px;
  left: calc(50% - 52px);
}

@media (max-width: 768px) {
  .section-header p {
    font-size: 28px;
    line-height: 32px;
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #012970;
  min-height: 40px;
  margin-top: 82px;
  color: #fff;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 57px;
  }
}

.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 500;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol a {
  color: #fff;
  transition: 0.3s;
}

.breadcrumbs ol a:hover {
  text-decoration: underline;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #8894f6;
  content: "/";
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: #4154f1;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #6776f4;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  transition: all 0.5s;
  z-index: 997;
  padding: 20px 0;
}

.header.header-scrolled {
  background: #fff;
  padding: 15px 0;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.header .logo {
  line-height: 0;
}

/* .header .logo img {
  max-height: 51px;
  margin-right: 6px;
} */

.header .logo span {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #012970;
  font-family: "Nunito", sans-serif;
  margin-top: 3px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #013289;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #4154f1;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  background: #4154f1;
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 4px;
  color: #fff;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: #5969f3;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 15px;
  text-transform: none;
  font-weight: 600;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #4154f1;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .megamenu {
  position: static;
}

.navbar .megamenu ul {
  margin-top: 5px;
  right: 0;
  padding: 10px;
  display: flex;
}

.navbar .megamenu ul li {
  flex: 1;
}

.navbar .megamenu ul li a,
.navbar .megamenu ul li:hover>a {
  color: #013289;
}

.navbar .megamenu ul li a:hover,
.navbar .megamenu ul li .active,
.navbar .megamenu ul li .active:hover {
  color: #4154f1;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #012970;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 1200px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(1, 22, 61, 0.9);
  transition: 0.3s;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #012970;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #4154f1;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #4154f1;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  height: 100vh;
  background: url(../img/hero-bg.png) top center no-repeat;
  background-size: cover;
}

.hero h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  color: #012970;
}

.hero h2 {
  color: #444444;
  margin: 15px 0 0 0;
  font-size: 26px;
}

.hero .btn-get-started {
  margin-top: 30px;
  line-height: 0;
  padding: 15px 40px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #4154f1;
  box-shadow: 0px 5px 30px rgba(65, 84, 241, 0.4);
}

.hero .btn-get-started span {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
}

.hero .btn-get-started i {
  margin-left: 5px;
  font-size: 18px;
  transition: 0.3s;
}

.hero .btn-get-started:hover i {
  transform: translateX(5px);
}

.hero .hero-img {
  text-align: right;
}

/* #hero .hero-img .animated{
  animation: up-down 2s ease-in-out infinite alternate-reverse both;

} */

@media (min-width: 1024px) {
  .hero {
    background-attachment: fixed;
  }
}

@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 120px 0 60px 0;
  }

  .hero .hero-img {
    text-align: center;
    margin-top: 80px;
  }

  .hero .hero-img img {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .hero {
    text-align: center;
  }

  .hero h1 {
    font-size: 32px;
  }

  .hero h2 {
    font-size: 24px;
  }

  .hero .hero-img img {
    width: 100%;
  }
}

/*--------------------------------------------------------------
# Index Page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content {
  background-color: #f6f9ff;
  padding: 40px;
}

.about h3 {
  font-size: 14px;
  font-weight: 700;
  color: #4154f1;
  text-transform: uppercase;
}

.about h2 {
  font-size: 24px;
  font-weight: 700;
  color: #012970;
}

.about p {
  margin: 15px 0 30px 0;
  line-height: 24px;
}

.about .btn-read-more {
  line-height: 0;
  padding: 15px 40px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #4154f1;
  box-shadow: 0px 5px 25px rgba(65, 84, 241, 0.3);
}

.about .btn-read-more span {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
}

.about .btn-read-more i {
  margin-left: 5px;
  font-size: 18px;
  transition: 0.3s;
}

.about .btn-read-more:hover i {
  transform: translateX(5px);
}

/*--------------------------------------------------------------
# Values
--------------------------------------------------------------*/
.values .box {
  padding: 30px;
  box-shadow: 0px 0 5px rgba(1, 41, 112, 0.08);
  /* box-shadow: 0px 0 25px 0 rgb(16 110 234 / 10%); */
  border: 1px solid #e2eefd;
  text-align: center;
  transition: 0.3s;
  height: 100%;
  background-color: white;
}

.values .box img {
  padding: 30px 50px;
  height: 215px;
  transition: 0.5s;
  transform: scale(1.1);
}

.values .assigned-quiz-cards .box img {
  padding: 30px 50px;
  height: 172px;
  transition: 0.5s;
  transform: scale(1.1);
}


.values .box h3 {
  font-size: 24px;
  color: #012970;
  font-weight: 700;
  margin-bottom: 18px;
}

.values .box:hover {
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
}

.values .box:hover img {
  transform: scale(1);
}


.values .btn-buy {
  display: inline-block;
  padding: 8px 40px 10px 40px;
  border-radius: 50px;
  color: #4154f1;
  transition: none;
  font-size: 16px;
  font-weight: 400;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  transition: 0.3s;
  border: 1px solid #4154f1;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
/* .counts {
  padding: 70px 0 60px;
} */

.counts {
  padding: 14px 0 60px;
}

.counts .count-box {
  display: flex;
  align-items: center;
  padding: 30px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
}

.counts .count-box i {
  font-size: 42px;
  line-height: 0;
  margin-right: 20px;
  /* color: #4154f1; */
  color: white;
}

.counts .count-box span {
  font-size: 36px;
  display: block;
  font-weight: 600;
  /* color: #0b198f; */
  color: white;
}

.counts .count-box p {
  padding: 0;
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  color: white;
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .feature-box {
  padding: 24px 20px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  transition: 0.3s;
  height: 100%;
}

.features .feature-box h3 {
  font-size: 18px;
  color: #012970;
  font-weight: 700;
  margin: 0;
}

.features .feature-box i {
  line-height: 0;
  background: #ecf3ff;
  padding: 4px;
  margin-right: 10px;
  font-size: 24px;
  border-radius: 3px;
  transition: 0.3s;
}

.features .feature-box:hover i {
  background: #4154f1;
  color: #fff;
}

.features .feture-tabs {
  margin-top: 120px;
}

.features .feture-tabs h3 {
  color: #012970;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .features .feture-tabs h3 {
    font-size: 28px;
  }
}

.features .feture-tabs .nav-pills {
  border-bottom: 1px solid #eee;
}

.features .feture-tabs .nav-link {
  background: none;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  color: #012970;
  padding: 12px 0;
  margin-right: 25px;
  margin-bottom: -2px;
  border-radius: 0;
}

.features .feture-tabs .nav-link.active {
  color: #4154f1;
  border-bottom: 3px solid #4154f1;
}

.features .feture-tabs .tab-content h4 {
  font-size: 18px;
  margin: 0;
  font-weight: 700;
  color: #012970;
}

.features .feture-tabs .tab-content i {
  font-size: 24px;
  line-height: 0;
  margin-right: 8px;
  color: #4154f1;
}

.features .feature-icons {
  margin-top: 120px;
}

.features .feature-icons h3 {
  color: #012970;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .features .feature-icons h3 {
    font-size: 28px;
  }
}

.features .feature-icons .content .icon-box {
  display: flex;
}

.features .feature-icons .content .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 10px 0;
  color: #012970;
}

.features .feature-icons .content .icon-box i {
  font-size: 44px;
  line-height: 44px;
  color: #0245bc;
  margin-right: 15px;
}

.features .feature-icons .content .icon-box p {
  font-size: 15px;
  color: #848484;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .service-box {
  box-shadow: 0px 0 30px rgba(1, 41, 112, 21%);
  height: 100%;
  padding: 20px 10px;
  text-align: center;
  transition: 0.3s;
  border-radius: 5px;
}

.services .service-box .icon {
  font-size: 36px;
  padding: 30px 13px;
  border-radius: 4px;
  position: relative;
  margin-bottom: 25px;
  display: inline-block;
  line-height: 0;
  transition: 0.3s;
}

.services .service-box h3 {
  color: #444444;
  font-weight: 700;
}

.services .service-box .read-more {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  padding: 8px 20px;
}

.services .service-box .read-more i {
  line-height: 0;
  margin-left: 5px;
  font-size: 18px;
}

.services .service-box.blue {
  border-bottom: 3px solid #2db6fa;
}

.services .service-box.blue .icon {
  color: #2db6fa;
  background: #dbf3fe;
}

.services .service-box.blue .read-more {
  color: #2db6fa;
}

.services .service-box.blue:hover {
  background: #2db6fa;
}

.services .service-box.orange {
  border-bottom: 3px solid #f68c09;
}

.services .service-box.orange .icon {
  color: #f68c09;
  background: #fde3c4;
}

.services .service-box.orange .read-more {
  color: #f68c09;
}

.services .service-box.orange:hover {
  background: #f68c09;
}

.services .service-box.green {
  border-bottom: 3px solid #08da4e;
}

.services .service-box.green .icon {
  color: #08da4e;
  background: #cffddf;
}

.services .service-box.green .read-more {
  color: #08da4e;
}

.services .service-box.green:hover {
  background: #08da4e;
}

.services .service-box.red {
  border-bottom: 3px solid #e9222c;
}

.services .service-box.red .icon {
  color: #e9222c;
  background: #fef7f8;
}

.services .service-box.red .read-more {
  color: #e9222c;
}

.services .service-box.red:hover {
  background: #e9222c;
}

.services .service-box.purple {
  border-bottom: 3px solid #b50edf;
}

.services .service-box.purple .icon {
  color: #b50edf;
  background: #f8e4fd;
}

.services .service-box.purple .read-more {
  color: #b50edf;
}

.services .service-box.purple:hover {
  background: #b50edf;
}

.services .service-box.pink {
  border-bottom: 3px solid #f51f9c;
}

.services .service-box.pink .icon {
  color: #f51f9c;
  background: #feecf7;
}

.services .service-box.pink .read-more {
  color: #f51f9c;
}

.services .service-box.pink:hover {
  background: #f51f9c;
}

.services .service-box:hover h3,
.services .service-box:hover p,
.services .service-box:hover .read-more {
  color: #fff;
}

.services .service-box:hover .hover-effect {
  color: white !important;
}



.services .service-box:hover .icon {
  background: #fff;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .box {
  padding: 40px 20px;
  background: #fff;
  text-align: center;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
}

.pricing .box:hover {
  transform: scale(1.1);
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.pricing h3 {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
}

.pricing .price {
  font-size: 36px;
  color: #444444;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.pricing .price sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing .price span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}

.pricing img {
  padding: 30px 40px;
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: #444444;
  text-align: center;
  line-height: 26px;
  font-size: 16px;
  margin-bottom: 25px;
}

.pricing ul li {
  padding-bottom: 10px;
}

.pricing ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing .btn-buy {
  display: inline-block;
  padding: 8px 40px 10px 40px;
  border-radius: 50px;
  color: #4154f1;
  transition: none;
  font-size: 16px;
  font-weight: 400;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  transition: 0.3s;
  border: 1px solid #4154f1;
}

.pricing .btn-buy:hover {
  background: #4154f1;
  color: #fff;
}

.pricing .featured {
  width: 200px;
  position: absolute;
  top: 18px;
  right: -68px;
  transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  padding: 1px 0 3px 0;
  background: #4154f1;
  color: #fff;
}

/*--------------------------------------------------------------
# F.A.Q
--------------------------------------------------------------*/
.faq .accordion-collapse {
  border: 0;
}

.faq .accordion-button {
  padding: 15px 15px 20px 0;
  font-weight: 600;
  border: 0;
  font-size: 18px;
  color: #444444;
  text-align: left;
}

.faq .accordion-button:focus {
  box-shadow: none;
}

.faq .accordion-button:not(.collapsed) {
  background: none;
  color: #4154f1;
  border-bottom: 0;
}

.faq .accordion-body {
  padding: 0 0 25px 0;
  border: 0;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 25px auto;
  list-style: none;
  text-align: center;
  background: white;
  border-radius: 50px;
  padding: 2px 15px;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 20px 10px 20px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  color: #444444;
  margin: 0 4px 8px 4px;
  transition: 0.3s;
  border-radius: 50px;
  border: 1px solid #fff;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #4154f1;
  border-color: #4154f1;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(255, 255, 255, 0.75);
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap img {
  transition: 1s;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolio .portfolio-wrap .portfolio-info::before {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  top: 35px;
  left: 35px;
  border-top: 3px solid rgba(1, 41, 112, 0.2);
  border-left: 3px solid rgba(1, 41, 112, 0.2);
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info::after {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 35px;
  right: 35px;
  border-bottom: 3px solid rgba(1, 41, 112, 0.2);
  border-right: 3px solid rgba(1, 41, 112, 0.2);
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #012970;
  font-weight: 700;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: #012970;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: #fff;
  background: #4154f1;
  margin: 10px 2px;
  width: 36px;
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a i {
  font-size: 24px;
  line-height: 0;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  background: #5969f3;
}

.portfolio .portfolio-wrap:hover img {
  transform: scale(1.1);
}

.portfolio .portfolio-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info::before {
  top: 15px;
  left: 15px;
}

.portfolio .portfolio-wrap:hover .portfolio-info::after {
  bottom: 15px;
  right: 15px;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #4154f1;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #4154f1;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px;
  margin: 40px 30px;
  box-shadow: 0px 0 20px rgba(1, 41, 112, 0.1);
  background: #fff;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  text-align: center;
  transition: 0.3s;
}

.testimonials .testimonial-item .stars {
  margin-bottom: 15px;
}

.testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50%;
  border: 4px solid #fff;
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #4154f1;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #4154f1;
}

.testimonials .swiper-slide {
  opacity: 0.3;
}

@media (max-width: 1199px) {
  .testimonials .swiper-slide-active {
    opacity: 1;
  }

  .testimonials .swiper-pagination {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .testimonials .swiper-slide-next {
    opacity: 1;
    transform: scale(1.12);
  }
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team {
  background: #fff;
  padding: 60px 0;
}

.team .member {
  overflow: hidden;
  text-align: center;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  transition: 0.3s;
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .member-img:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: url(../img/team-shape.svg) no-repeat center bottom;
  background-size: contain;
  z-index: 1;
}

.team .member .social {
  position: absolute;
  right: -100%;
  top: 30px;
  opacity: 0;
  border-radius: 4px;
  transition: 0.5s;
  background: rgba(255, 255, 255, 0.3);
  z-index: 2;
}

.team .member .social a {
  transition: color 0.3s;
  color: rgba(1, 41, 112, 0.5);
  margin: 15px 12px;
  display: block;
  line-height: 0;
  text-align: center;
}

.team .member .social a:hover {
  color: rgba(1, 41, 112, 0.8);
}

.team .member .social i {
  font-size: 18px;
}

.team .member .member-info {
  padding: 10px 15px 20px 15px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  color: #012970;
}

.team .member .member-info span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #aaaaaa;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  padding-top: 15px;
  line-height: 26px;
  color: #5e5e5e;
}

.team .member:hover {
  transform: scale(1.08);
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.team .member:hover .social {
  right: 8px;
  opacity: 1;
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients .clients-slider .swiper-slide img {
  opacity: 0.5;
  transition: 0.3s;
}

.clients .clients-slider .swiper-slide img:hover {
  opacity: 1;
}

.clients .clients-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.clients .clients-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #4154f1;
}

.clients .clients-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #4154f1;
}

/*--------------------------------------------------------------
# Recent Blog Posts
--------------------------------------------------------------*/
.recent-blog-posts .post-box {
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  transition: 0.3s;
  height: 100%;
  overflow: hidden;
  padding: 30px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.recent-blog-posts .post-box .post-img {
  overflow: hidden;
  margin: -30px -30px 15px -30px;
  position: relative;
}

.recent-blog-posts .post-box .post-img img {
  transition: 0.5s;
}

.recent-blog-posts .post-box .post-date {
  font-size: 16px;
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
  display: block;
  margin-bottom: 10px;
}

.recent-blog-posts .post-box .post-title {
  font-size: 24px;
  color: #012970;
  font-weight: 700;
  margin-bottom: 18px;
  position: relative;
  transition: 0.3s;
}

.recent-blog-posts .post-box .readmore {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 1;
  transition: 0.3s;
}

.recent-blog-posts .post-box .readmore i {
  line-height: 0;
  margin-left: 4px;
  font-size: 18px;
}

.recent-blog-posts .post-box:hover .post-title {
  color: #4154f1;
}

.recent-blog-posts .post-box:hover .post-img img {
  transform: rotate(6deg) scale(1.2);
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  color: #444444;
  background: #fafbff;
  padding: 30px;
}

.contact .info-box i {
  font-size: 38px;
  line-height: 0;
  color: #4154f1;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #012970;
  font-weight: 700;
  margin: 20px 0 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  background: #fafbff;
  padding: 30px;
  height: 100%;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 0;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #4154f1;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type=submit] {
  background: #4154f1;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #5969f3;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
  padding: 40px 0 20px 0;
}

.blog .entry {
  padding: 30px;
  margin-bottom: 60px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .entry .entry-img {
  max-height: 440px;
  margin: -30px -30px 20px -30px;
  overflow: hidden;
}

.blog .entry .entry-title {
  font-size: 28px;
  font-weight: bold;
  padding: 0;
  margin: 0 0 20px 0;
}

.blog .entry .entry-title a {
  color: #012970;
  transition: 0.3s;
}

.blog .entry .entry-title a:hover {
  color: #4154f1;
}

.blog .entry .entry-meta {
  margin-bottom: 15px;
  color: #4084fd;
}

.blog .entry .entry-meta ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog .entry .entry-meta ul li+li {
  padding-left: 20px;
}

.blog .entry .entry-meta i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
}

.blog .entry .entry-meta a {
  color: #777777;
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog .entry .entry-content p {
  line-height: 24px;
}

.blog .entry .entry-content .read-more {
  -moz-text-align-last: right;
  text-align-last: right;
}

.blog .entry .entry-content .read-more a {
  display: inline-block;
  background: #4154f1;
  color: #fff;
  padding: 6px 20px;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 4px;
}

.blog .entry .entry-content .read-more a:hover {
  background: #5969f3;
}

.blog .entry .entry-content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog .entry .entry-content blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog .entry .entry-content blockquote p {
  color: #444444;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog .entry .entry-content blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #012970;
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog .entry .entry-footer {
  padding-top: 10px;
  border-top: 1px solid #e6e6e6;
}

.blog .entry .entry-footer i {
  color: #0d64fd;
  display: inline;
}

.blog .entry .entry-footer a {
  color: #013289;
  transition: 0.3s;
}

.blog .entry .entry-footer a:hover {
  color: #4154f1;
}

.blog .entry .entry-footer .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog .entry .entry-footer .cats li {
  display: inline-block;
}

.blog .entry .entry-footer .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog .entry .entry-footer .tags li {
  display: inline-block;
}

.blog .entry .entry-footer .tags li+li::before {
  padding-right: 6px;
  color: #6c757d;
  content: ",";
}

.blog .entry .entry-footer .share {
  font-size: 16px;
}

.blog .entry .entry-footer .share i {
  padding-left: 5px;
}

.blog .entry-single {
  margin-bottom: 30px;
}

.blog .blog-author {
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-author img {
  width: 120px;
  margin-right: 20px;
}

.blog .blog-author h4 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding: 0;
  color: #012970;
}

.blog .blog-author .social-links {
  margin: 0 10px 10px 0;
}

.blog .blog-author .social-links a {
  color: rgba(1, 41, 112, 0.5);
  margin-right: 5px;
}

.blog .blog-author p {
  font-style: italic;
  color: #b7b7b7;
}

.blog .blog-comments {
  margin-bottom: 30px;
}

.blog .blog-comments .comments-count {
  font-weight: bold;
}

.blog .blog-comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog .blog-comments .comment .comment-img {
  margin-right: 14px;
}

.blog .blog-comments .comment .comment-img img {
  width: 60px;
}

.blog .blog-comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}

.blog .blog-comments .comment h5 a {
  font-weight: bold;
  color: #444444;
  transition: 0.3s;
}

.blog .blog-comments .comment h5 a:hover {
  color: #4154f1;
}

.blog .blog-comments .comment h5 .reply {
  padding-left: 10px;
  color: #012970;
}

.blog .blog-comments .comment h5 .reply i {
  font-size: 20px;
}

.blog .blog-comments .comment time {
  display: block;
  font-size: 14px;
  color: #013ca3;
  margin-bottom: 5px;
}

.blog .blog-comments .comment.comment-reply {
  padding-left: 40px;
}

.blog .blog-comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.blog .blog-comments .reply-form p {
  font-size: 14px;
}

.blog .blog-comments .reply-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form input:focus {
  box-shadow: none;
  border-color: #a0aaf8;
}

.blog .blog-comments .reply-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: #a0aaf8;
}

.blog .blog-comments .reply-form .form-group {
  margin-bottom: 25px;
}

.blog .blog-comments .reply-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: #012970;
}

.blog .blog-comments .reply-form .btn-primary:hover {
  background-color: #013289;
}

.blog .blog-pagination {
  color: #024ed5;
}

.blog .blog-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog .blog-pagination li {
  margin: 0 5px;
  transition: 0.3s;
}

.blog .blog-pagination li a {
  color: #012970;
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog .blog-pagination li.active,
.blog .blog-pagination li:hover {
  background: #4154f1;
}

.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
  color: #fff;
}

.blog .sidebar {
  padding: 30px;
  margin: 0 0 60px 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 0 0;
  margin: 0 0 15px 0;
  color: #012970;
  position: relative;
}

.blog .sidebar .sidebar-item {
  margin-bottom: 30px;
}

.blog .sidebar .search-form form {
  background: #fff;
  border: 1px solid #ddd;
  padding: 3px 10px;
  position: relative;
}

.blog .sidebar .search-form form input[type=text] {
  border: 0;
  padding: 4px;
  border-radius: 4px;
  width: calc(100% - 40px);
}

.blog .sidebar .search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  background: #4154f1;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  line-height: 0;
}

.blog .sidebar .search-form form button i {
  line-height: 0;
}

.blog .sidebar .search-form form button:hover {
  background: #5465f2;
}

.blog .sidebar .categories ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .categories ul li+li {
  padding-top: 10px;
}

.blog .sidebar .categories ul a {
  color: #012970;
  transition: 0.3s;
}

.blog .sidebar .categories ul a:hover {
  color: #4154f1;
}

.blog .sidebar .categories ul a span {
  padding-left: 5px;
  color: #aaaaaa;
  font-size: 14px;
}

.blog .sidebar .recent-posts .post-item+.post-item {
  margin-top: 15px;
}

.blog .sidebar .recent-posts img {
  width: 80px;
  float: left;
}

.blog .sidebar .recent-posts h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
}

.blog .sidebar .recent-posts h4 a {
  color: #012970;
  transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
  color: #4154f1;
}

.blog .sidebar .recent-posts time {
  display: block;
  margin-left: 95px;
  font-style: italic;
  font-size: 14px;
  color: #aaaaaa;
}

.blog .sidebar .tags {
  margin-bottom: -10px;
}

.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .tags ul li {
  display: inline-block;
}

.blog .sidebar .tags ul a {
  color: #0257ee;
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid #d7e6ff;
  display: inline-block;
  transition: 0.3s;
}

.blog .sidebar .tags ul a:hover {
  color: #fff;
  border: 1px solid #4154f1;
  background: #4154f1;
}

.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: #a5c5fe;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  background: #003366;
  /* padding: 0 0 30px 0; */
  font-size: 14px;
}

.footer .footer-newsletter {
  padding: 50px 0;
  background: #f6f9ff;
  border-top: 1px solid #e1ecff;
}

.footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 10px 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  color: #012970;
}

.footer .footer-newsletter form {
  margin-top: 20px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
  border: 1px solid #e1ecff;
}

.footer .footer-newsletter form input[type=email] {
  border: 0;
  padding: 8px;
  width: calc(100% - 140px);
}

.footer .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 30px;
  margin: 3px;
  background: #4154f1;
  color: #fff;
  transition: 0.3s;
  border-radius: 4px;
}

.footer .footer-newsletter form input[type=submit]:hover {
  background: #5969f3;
}

.footer .footer-top {
  /* position: relative;
  background: #012970;
  border-top: 1px solid #e1ecff;
  border-bottom: 1px solid #e1ecff;
  padding: 60px 0 30px 0;
  overflow: hidden;  */

 /* background:  linear-gradient(to right, rgb(39, 70, 133) 0%, rgb(61, 179, 197) 100%); */
 background-color: #4B49AC;
}

/* Additional CSS for the provided SVG */
.footer .footer-new-wave-svg {  
  background-color: transparent;
  display: block;
  height: 30px;
  position: relative;
  top: -1px;
  width: 100%;
}

.footer .footer-new-wave-path {
  fill: #f6f9ff; /* Match the background color */
}


@media (max-width: 992px) {
  .footer .footer-top {
    background-position: center bottom;
  }
}

.footer .footer-top .footer-info {
  margin-bottom: 30px;
}

.footer .footer-top .footer-info .logo {
  line-height: 0;
  margin-bottom: 15px;
}

.footer .footer-top .footer-info .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.footer .footer-top .footer-info .logo span {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  color: white;
  font-family: "Nunito", sans-serif;
  margin-top: 3px;
}

.footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Nunito", sans-serif;
}

.footer .footer-top .social-links a {
  font-size: 20px;
  display: inline-block;
  color: white;
  line-height: 0;
  margin-right: 10px;
  transition: 0.3s;
}

.footer .footer-top .social-links a:hover {
  color: white;
}

.footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
}

.footer .footer-top .footer-links {
  margin-bottom: 30px;
}

.footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #d0d4fc;
  font-size: 12px;
  line-height: 0;
}

.footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-top .footer-links ul a {
  color: white;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

.footer .footer-top .footer-links ul a:hover {
  color: #4154f1;
}

.footer .footer-top .footer-contact p {
  line-height: 26px;
}

.footer .copyright {
  text-align: center;
  padding-top: 20px;
  color: white;
  /* background-color: #027b9a; */
  padding-bottom: 18px;
}

.footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #012970;
}




/*---------------- Custom CSS--------------- */

.wrapper {
  margin-top: 50px;
  min-height: 400px;
  margin-bottom: 20px;

}

.wrapper-home {
  margin-top: 107px;
  min-height: 400px;
  margin-bottom: 20px;

}

.btn-theme {
  width: 200px;
  /* height: 48px; */
  background-color: #4154f1;
  ;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  font-weight: bold;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;

}


.btn-theme-download {
  /* width:200px; 
   height: 48px; */
  background-color: #4154f1;  
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  font-weight: bold;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;

}


/* -----Home page cards css ----*/

.quiz-period {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.start-date {
  padding-left: 15px;
  font-size: 0.8em;
  font-weight: bold;
  color: #18A558;
}

.end-date {
  padding-left: 45px;
  font-size: 0.8em;
  font-weight: bold;
  color: red;
}

.question-detail {
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  color: #5E5E5E;

}

.noOf-ques {
  padding: 0 10px;
  border-right: 1px solid #DBDBDB;
}

.qcount {

  font-size: 1.583em;
  font-weight: 700;
  color: #000;

}

.quiz_time {
  padding: 0 10px;
}

.time_duration {
  display: flex;
  align-items: center;
  font-size: 1.583em;
  font-weight: 700;
  color: #000;
}

.StartEndspan {
  color: brown;
}

/* Dropdown css  */

.custom-select select {
  background-color: #4154f1;
  color: white;
  border-radius: 4px;
  background-image: none;
  appearance: auto;

}

.custom-select option {
  background-color: white;

  color: black;

}

.custom-select select:active,
.custom-select select:focus {
  outline: none;
  box-shadow: none;
}

.custom-select .form-select:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 8px;
  width: 0;
  height: 0;
  margin-top: -2px;
  border-top: 5px solid #aaa;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

/* Dropdown for Quiz */

.custom-select-quiz select {
  background-color: lightgray;
  color: black;
  border-radius: 4px;
  background-image: none;
  appearance: auto;

}

.custom-select-quiz option {
  background-color: white;
  /* Change background color of each option */
  color: black;
  /* Change text color of each option */
}

.custom-select-quiz select:active,
.custom-select select:focus {
  outline: none;
  box-shadow: none;
}

.custom-select-quiz .form-select:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 8px;
  width: 0;
  height: 0;
  margin-top: -2px;
  border-top: 5px solid #aaa;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}



/* Quiz Attempt */

.unselectable {
  user-select: none;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: auto;
  max-height: 100%;
}

code {
  display: block;
}

/* Single choice Question */
.fa-square-plus,
.fa-square-minus {
  color: #4154f1;
}


/* Fill in the Blanks */

#option1,
#correctOption {
  width: 100%;
  padding: 8px 16px;
  margin: 8px 0;
  box-sizing: border-box;
  /* border: 1px solid black; */
  border-radius: 4px;
  background-color: lightgray;
}


/* Loader */
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


/* User Dashboard */

.user-dashboard thead {
  background-color: #ced0f0;
  color: black;
  border:none;
  font-size: 14px;
}

.searchWrapper {
  background-color: lightgray;
}

.question-multiselect .searchWrapper {
  background-color: #4154f1;

}

.question-multiselect .searchWrapper ::placeholder {
  /* background-color: #4154f1; */
  color: white;

}

/* Challenge Cards in UserDashboard */




.challenge-card {
  border-radius: 8px;
  border: 1px solid #cccccc;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  padding-top: 28px;
  box-sizing: border-box;
  /* width: 200px; */
  height: 200px;
  transition: all linear 200ms;
}

.challenge-card:hover {
  transform: scale(1.1);
  transition: all linear 200ms;
  z-index: 1;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

/* Different hover colors */
.challenge-card:hover h5 {
  color: #fff;
}



.challenge-card:hover .hover-effect-wrapper .hover-effect {
  color: white !important;
}

.challenge-card:hover.color1 {
  background-color: #f9b234;

}

.challenge-card:hover.color2 {
  background-color: #e44002;

}

.challenge-card:hover.color3 {
  background-color: #952aff;

}

.challenge-card:hover.color4 {
  background-color: #cd3e94;

}

/* Accordion for Hint in User dashboard */

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
  background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

/* Login Password */
.eye-icon-container {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
}


/* Dashboard Cards */

.section_our_solution .row {
  align-items: center;
}

.our_solution_category {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.our_solution_category .solution_cards_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.solution_cards_box .solution_card {
  flex: 0 50%;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgb(136 144 195), 0 5px 15px 0 rgb(37 44 97);
  border-radius: 15px;
  margin: 8px;
  padding: 10px 15px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 265px;
  transition: 0.7s;
}

.solution_cards_box .solution_card:hover {
  background: #309df0;
  color: #fff;
  transform: scale(1.1);
  z-index: 9;
}

.solution_cards_box .solution_card:hover::before {
  background: rgb(85 108 214 / 10%);
}

.solution_cards_box .solution_card:hover .solu_title h3,
.solution_cards_box .solution_card:hover .solu_description p {
  color: #fff;
}

.solution_cards_box .solution_card:before {
  content: "";
  position: absolute;
  background: rgb(85 108 214 / 5%);
  width: 170px;
  height: 400px;
  z-index: -1;
  transform: rotate(42deg);
  right: -56px;
  top: -23px;
  border-radius: 35px;
}

.solution_cards_box .solution_card:hover .solu_description button {
  background: #fff !important;
  color: #309df0;
}

.solution_card .so_top_icon {}

.solution_card .solu_title h3 {
  color: rgb(33, 38, 79);
  ;
  font-size: 1.3rem;
  margin-top: 13px;
  margin-bottom: 13px;
}

.solution_card .solu_description p {
  font-size: 15px;
  margin-bottom: 15px;
}

.solution_card .solu_description button {
  border: 0;
  border-radius: 15px;
  background: linear-gradient(140deg,
      #42c3ca 0%,
      #42c3ca 50%,
      #42c3cac7 75%) !important;
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
  padding: 5px 16px;
}

.our_solution_content h1 {
  text-transform: capitalize;
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

.our_solution_content p {}

.hover_color_bubble {
  position: absolute;
  background: rgb(54 81 207 / 15%);
  width: 100rem;
  height: 100rem;
  left: 0;
  right: 0;
  z-index: -1;
  top: 16rem;
  border-radius: 50%;
  transform: rotate(-36deg);
  left: -18rem;
  transition: 0.7s;
}

.solution_cards_box .solution_card:hover .hover_color_bubble {
  top: 0rem;
}

.solution_cards_box .solution_card .so_top_icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: hsla(210, 90%, 80%, 1);
  ;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.solution_cards_box .solution_card .so_top_icon img {
  width: 40px;
  height: 50px;
  object-fit: contain;
}


@media screen and (min-width: 320px) {
  .sol_card_top_3 {
    position: relative;
    top: 0;
  }

  .our_solution_category {
    width: 100%;
    margin: 0 auto;
  }

  .our_solution_category .solution_cards_box {
    flex: auto;
  }
}

@media only screen and (min-width: 768px) {
  .our_solution_category .solution_cards_box {
    flex: 1;
  }
}

@media only screen and (min-width: 1024px) {
  .sol_card_top_3 {
    position: relative;
    top: -3rem;
  }

  .our_solution_category {
    /* width: 50%; */
    margin: 0 auto;
  }
}





/* CTF Dashboard */

.ag-format-container {
  width: 1142px;
  margin: 0 auto;
}


.ag-courses_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  /* padding: 50px 0; */
}

.ag-courses_item {
  -ms-flex-preferred-size: calc(33.33333% - 30px);
  flex-basis: calc(33.33333% - 30px);
  box-shadow: 0px 0 30px rgb(1 41 112 / 21%);
  margin: 0 15px 30px;

  overflow: hidden;

  border-radius: 28px;
}

.ag-courses-item_link {
  display: block;
  padding: 30px 20px;
  background-color: #fff;

  overflow: hidden;

  position: relative;
}




.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_course,
.ag-courses-item_link:hover .ag-courses-item_title,
.ag-courses-item_link:hover .ag-courses-item_start_date,
.ag-courses-item_link:hover .ag-courses-item_end_date,
.ag-courses-item_link:hover small {
  text-decoration: none !important;
  color: #FFF !important;
}



.ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}


.ag-courses-item_course {
  color: black;
}



.ag-courses-item_title {
  min-height: 33px;
  margin: 0 0 25px;
  color: black;
  font-weight: 700;
  overflow: hidden;
  /* font-weight: bold; */
  font-size: 20px;
  /* color: #FFF; */

  z-index: 2;
  position: relative;
}

.ag-courses-item_date-box {
  font-size: 18px;
  color: #FFF;

  z-index: 2;
  position: relative;
}

.ag-courses-item_start_date {
  font-weight: bold;
  color: #198754 !important;

  -webkit-transition: color .5s ease;
  -o-transition: color .5s ease;
  transition: color .5s ease
}

.ag-courses-item_end_date {
  font-weight: bold;
  color: #dc3545 !important;
  -webkit-transition: color .5s ease;
  -o-transition: color .5s ease;
  transition: color .5s ease
}

.ag-courses-item_bg {
  height: 128px;
  width: 128px;
  background-color: #f9b234;

  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;

  border-radius: 50%;

  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
}

.ag-courses_item:nth-child(2n) .ag-courses-item_bg {
  background-color: #3ecd5e;
}

.ag-courses_item:nth-child(3n) .ag-courses-item_bg {
  background-color: #e44002;
}

.ag-courses_item:nth-child(4n) .ag-courses-item_bg {
  background-color: #952aff;
}

.ag-courses_item:nth-child(5n) .ag-courses-item_bg {
  background-color: #cd3e94;
}

.ag-courses_item:nth-child(6n) .ag-courses-item_bg {
  background-color: #4c49ea;
}



@media only screen and (max-width: 979px) {
  .ag-courses_item {
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
  }

  .ag-courses-item_title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }

}

@media only screen and (max-width: 639px) {
  .ag-courses_item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .ag-courses-item_title {
    min-height: 72px;
    line-height: 1;

    font-size: 24px;
  }

  .ag-courses-item_link {
    padding: 22px 40px;
  }

  .ag-courses-item_date-box {
    font-size: 16px;
  }
}

/* .ag-courses-item_link:hover .btn-buy {
  background: #4154f1 !important;
  color: #fff !important;
  
} */

/* CTF Dashboard Cards */


.box-tech {
  position: relative;
  max-height: 255px;
  height: 255px;
  background-color: #eeeeee;
  display: flex;
  align-content: center;
  justify-content: center;
  overflow: hidden;
  padding: 2em;
}

.box-tech .content {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-flow: column;
  padding-top: 5em;
  transition: all 0.2s ease-in-out;
}

.box-tech .content img {
  width: 140px;
  transition: all 0.2s ease-in-out;
  filter: grayscale(100%) brightness(75%);
}

.box-tech .content p {
  font-size: 1.2em;
  font-weight: 700;
  color: #8E8E8E;
  /* text-transform: uppercase; */
  margin-top: 0em;
  transition: all 0.2s ease-in-out;
}

.box-tech .content .desc {
  transform: scale(0, 0);
  transition: all 0.2s ease-in-out;
}

.box-tech .content .desc .line {
  border-top: 1px solid #000000;
  width: 0px;
  margin-top: 0em;
  margin-bottom: 1em;
  transition: all 0.2s ease-in-out;
}

.box-tech .content .desc p {
  font-size: 1em !important;
  font-weight: 600;
  text-transform: none;
  margin-top: 0em;
}

.box-tech:hover .content {
  padding-top: 0em;
  cursor: pointer;
}

.box-tech:hover .content img {
  width: 60px;
  filter: none;
}

.box-tech:hover .content p {
  color: #111111;
}

.box-tech:hover .content .desc {
  display: block !important;
  transform: scale(1, 1);
}

.box-tech:hover .content .desc .line {
  width: 50px;
}

/* User Quiz Dashboard Cards */

.assigned-quiz-cards .icon-box {
  padding: 18px;
  position: relative;
  width: 260px;
  /* height: 242px; */
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
}

.assigned-quiz-cards .icon-box::before {
  content: "";
  position: absolute;
  background: #e1f0fa;
  right: -60px;
  top: -40px;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  transition: all 0.3s;
  z-index: -1;
}

.assigned-quiz-cards .icon-box:hover::before {
  background: #3498db;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 0px;
}

.assigned-quiz-cards .icon {
  margin: 0 auto 20px auto;
  padding-top: 10px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background: #3498db;
  transition: all 0.3s ease-in-out;
}

.assigned-quiz-cards .icon i {
  font-size: 36px;
  line-height: 1;
  color: #fff;
}

.assigned-quiz-cards .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
  text-align: center;
}

.assigned-quiz-cards .title a {
  color: #111;
}

.assigned-quiz-cards .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}

.assigned-quiz-cards .icon-box:hover .title a,
.assigned-quiz-cards .icon-box:hover .description {
  color: #fff;
}

.assigned-quiz-cards .icon-box:hover .icon {
  background: #fff;
}

.assigned-quiz-cards .icon-box:hover .icon i {
  color: #3498db;
}

.assigned-quiz-cards .icon-box:hover .hover-effect {
  color: white !important;
}


/* Displaying of error message css for multiselect dropdown */
.error-border {
  border: 1px solid red;
  border-radius: 5px;
}

.multiselect-container {
  position: relative;
}

.single-choice .optionListContainer {
  z-index: 100;
}

.input-icon-container {
  /* display: flex; */
  align-items: center;
}

.error-icon {
  color: red;
  margin-left: 5px;
  /* Adjust the margin as needed to position the icon */
  position: absolute;
  right: 10px;
  /* Adjust the position as needed to place the icon at the right end of the input */
  top: 26%;
  /* Adjust the position vertically if necessary */
  transform: translateY(-50%);
  /* Center the icon vertically */
}

.error-select {
  width: 100%;
  margin-top: 0.25rem;
  font-size: .875em;
  color: #dc3545;
}

.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  /* background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e); */
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}


/* Dashboard Spinner Css */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* HomeNavbar */

.bg-custom-1 {
  background-color: #33ccff;
}


.bground-custom-2 {
  /* background-image: linear-gradient(15deg, #990099 20%,#000066 100%); */
  /* background: linear-gradient(90deg,  rgba(0,212,255,1) 10%,  rgba(56,56,122,1)54%,rgba(56,56,122,1)  100%); */
  /* background-image: linear-gradient(15deg,#151983 0%, #80d0c7 100%); */
  /* background-color:  	 #8c8c8c; */
  background: #004672;
  /* background: -webkit-linear-gradient(to right, #ec2F4B, #009FFF); 
    background: linear-gradient(to right, #dd8a96, #004672);  */

}

.btns-rounded {
  border: 2px #ffc107;
  border-radius: 15px;
  width: 90px;
  text-align: center;
  font-weight: bold;
  margin: 4px;
  height: 40px;
  background-color: #ffc107;
  margin-left: 20px;
  font-size: 12px;

}

.btns-rounded:hover {
  background-color: #FFFF8A;
}

/* Dashboard Nav */

.bg-custom-1 {
  background-color: #85144b;
}

.bg-custom-2 {
  background: #004672;
}

.profile {
  height: 60px;
  width: 50px;
  margin-right: 50px;
}

#logo2 {
  height: 60px;
  width: 70px;
  margin-left: 50px;

}

/*   
      #logo {
        height: 60px;
        width: 40px;
        margin-left: 20px;
      } */


.titleNav {
  color: white;
  font-weight: bold;
  font-size: 15px;
}

.titleNav1 {
  color: white;
  font-weight: bold;
  font-size: 15px;
}

.dropNav {
  /* border: 2px #FFFF8A;
        border-radius: 15px; */
  width: 115px;
  text-align: center;
  margin: 2px;
  /* background-color: #FFFF8A; */


}

.dropItem {
  font-weight: bold;
  font-size: 15px;
  text-align: center;
}


.dropItem li:hover {
  background-color: #C5C5C5;
}

.dropNav1 {
  border: 2px;
  /* border-radius: 5px; */
  width: 130px;
  text-align: center;
  /* margin-right:50px; */
  /* background-color: #003060; */
  /* margin-left: 20px; */

}

.dropNav1:hover {
  background-color: #003060;
  border-radius: 15px;
  border-radius: 5px;
  transition: 0.3s ease;

}

.dropNav:hover {
  background-color: #003060;
  border-radius: 5px;
  transition: 0.3s ease;

}


/* Home Css */

.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(-225deg,
      #231557 0%,
      #44107a 29%,
      #ff1361 67%,
      #fff800 100%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 50px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

@keyframes down-btn {
  0% {
    bottom: 20px;
  }

  100% {
    bottom: 0px;
  }

  0% {
    opacity: 0;
  }

  100% {
    opaicty: 1;
  }
}

@-webkit-keyframes down-btn {
  0% {
    bottom: 20px;
  }

  100% {
    bottom: 0px;
  }

  0% {
    opacity: 0;
  }

  100% {
    opaicty: 1;
  }
}

@-moz-keyframes down-btn {
  0% {
    bottom: 20px;
  }

  100% {
    bottom: 0px;
  }

  0% {
    opacity: 0;
  }

  100% {
    opaicty: 1;
  }
}

@-o-keyframes down-btn {
  0% {
    bottom: 20px;
  }

  100% {
    bottom: 0px;
  }

  0% {
    opacity: 0;
  }

  100% {
    opaicty: 1;
  }
}


.category-name {
  font-family: sans-serif;
  width: -webkit-fill-available;
  text-align: center;
  font-size: 40px;
}

.card-category-2 ul,
.card-category-3 ul,
.card-category-4 ul,
.card-category-5 ul .card-category-6 ul {
  padding: 0;

}

.card-category-2 ul li,
.card-category-3 ul li,
.card-category-4 ul li,
.card-category-5 ul li,
.card-category-6 ul li {
  list-style-type: none;
  display: inline-block;
  vertical-align: top;
}

.card-category-2 ul li,
.card-category-3 ul li {
  margin: 10px 20px;
}

.card-category-1,
.card-category-2,
.card-category-3,
.card-category-4,
.card-category-5,
.card-category-6 {
  font-family: sans-serif;
  margin-bottom: 45px;

}

.card-category-1 div,
.card-category-2 div {
  display: inline-block;
}

.card-category-1>div,
.card-category-2>div:not(:last-child) {
  margin: 10px 5px;
  text-align: left;
}




/* Image Card */
.img-card {
  width: 250px;
  position: relative;
  border-radius: 5px;
  /* text-align: left; */
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
}

.img-card .card-image {
  position: relative;
  margin: auto;
  overflow: hidden;
  border-radius: 5px 5px 0px 0px;
  height: 250px;
}

.img-card .card-image img {
  width: 100%;
  border-radius: 5px 5px 0px 0px;

  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
}

.img-card .card-image:hover img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.img-card .card-text {
  padding: 0 15px 0px;
  line-height: 1.5;
  color: #FF9800;
}

.img-card .card-link {
  padding: 20px 15px 30px;
  width: -webkit-fill-available;
}

.img-card .card-link a {
  text-decoration: none;
  position: relative;
  padding: 10px 0;
}

.img-card .card-link a:after {
  top: 30px;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  width: 0;

  -webkit-transition: width 0.3s ease 0s, left 0.3s ease 0s;
  -moz-transition: width 0.3s ease 0s, left 0.3s ease 0s;
  -o-transition: width 0.3s ease 0s, left 0.3s ease 0s;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
}

.img-card .card-link a:hover:after {
  width: 100%;
  left: 0;
}

.img-card.iCard-style1 .card-title {
  position: absolute;
  font-family: 'Open Sans', sans-serif;
  z-index: 1;
  top: 10px;
  left: 10px;
  font-size: 30px;
  color: #fff;
}

.img-card.iCard-style1 .card-text {
  color: #FF9800;
  font-weight: bold;

}

.img-card.iCard-style1 .card-link a {
  color: #FF9800;
}

.img-card.iCard-style1 .card-link a:after {
  background: #FF9800;
}

.img-card.iCard-style2 .card-title {
  padding: 15px;
  font-size: 25px;
  font-family: 'Roboto', sans-serif;
}

.img-card.iCard-style2 .card-image {
  margin-bottom: 15px;
}

.img-card.iCard-style2 .card-caption {
  text-align: center;
  top: 80%;
  font-size: 17px;
  color: #fff;
  position: absolute;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  z-index: 1;
}

.img-card.iCard-style2 .card-link a {
  border: 1px solid;
  padding: 8px;
  border-radius: 3px;
  color: black;
  font-size: 13px;

  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.img-card.iCard-style2 .card-link a:hover {
  background: black;
}

.img-card.iCard-style2 .card-link a:hover span {
  color: #fff;
}

.img-card.iCard-style3 {
  text-align: center;
}

.img-card.iCard-style3 .card-title {
  top: 80%;
  font-size: 30px;
  color: #fff;
  position: absolute;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  z-index: 1;
}

.img-card.iCard-style3 .card-text {
  color: #FF9800;
  font-weight: bold;
}

.img-card.iCard-style3 .card-link {
  border-top: 1px solid #e8e8e8;
}

.img-card.iCard-style3 .card-link a {
  color: #FF9800;
}

.img-card.iCard-style3 .card-link a:after {
  background: #FF9800;
}

.img-card.iCard-style4 {
  text-align: right;
}

.img-card.iCard-style4 .card-caption {
  position: absolute;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  z-index: 1;
  top: 10px;
  right: 10px;
  color: #fff;
}

.img-card.iCard-style4 .card-title {
  width: 100%;
  padding: 20px 0px 12px 0;
  color: #E91E63;
}

.img-card.iCard-style4 .card-title span {
  font-size: 25px;
  margin-right: 12px;
}

.img-card.iCard-style4 .toggle {
  cursor: pointer;
  padding: 8px 0px 13px 0px;
  width: 55px;
}

.img-card.iCard-style4 .toggle span,
.img-card.iCard-style4 .toggle span:nth-child(2),
.img-card.iCard-style4 .toggle span:nth-child(3) {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #E91E63;
  cursor: pointer;
}

.img-card.iCard-style4 .toggle span:nth-child(1) {
  right: 15px;
}

.img-card.iCard-style4 .toggle span:nth-child(2) {
  right: 27px;
}

.img-card.iCard-style4 .toggle span:nth-child(3) {
  right: 39px;
}

.img-card.iCard-style4 .card-text {
  opacity: 0;
  line-height: 0;
  padding-bottom: 0;
  color: #FF9800;
  font-weight: bold;

  -webkit-transition: ease-in-out 0.5s;
  -moz-transition: ease-in-out 0.5s;
  -o-transition: ease-in-out 0.5s;
  transition: ease-in-out 0.5s;
}

.img-card.iCard-style4 .toggle.active~.card-text {
  opacity: 1;
  line-height: 1.5;
  padding-bottom: 10px;
  color: #FF9800;
}

.img-card.iCard-style4 .card-link {
  border-top: 1px solid #e8e8e8;
}

.img-card.iCard-style4 .card-link a {
  color: #E91E63;
}

.img-card.iCard-style4 .card-link a:after {
  background: #E91E63;
}


.card-Home-Image {
  height: 250px;

}


/* Login Css */

/* body{
  font-family: Georgia, 'Times New Roman', Times, serif
} */

.quizz {
  height: 500px;
}

.btns-rounded-login {
  border: 2px #2471A3;
  border-radius: 15px;
  width: 85%;
  text-align: center;
  font-weight: bold;
  /* margin: 4px; */
  height: 50px;
  color: white;
  background-color: #2471A3;
  /* margin-left: 20px; */

}

.btns-rounded-login:hover {
  background-color: #FF8A8A;
}

.btn-circle {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
}

.btn-circle i {
  position: relative;
  top: -1px;
}

.btn-circle-lg {
  width: 55px;
  height: 55px;
  line-height: 55px;
  font-size: 1.1rem;
}

.btn-fb {
  background-color: #3b5998;
}


.btn-color {
  background-color: #0e1c36;
  color: #fff;

}

.btn-color:hover {
  background-color: #145DA0;
  color: white;
}

.profile-image-pic {
  height: 200px;
  width: 200px;
  object-fit: cover;
}



.cardbody-color {
  background-color: #ebf2fa;
}

a {
  text-decoration: none;
}

.border-left-right {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  border: none;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  padding: 24px 0 0;
  padding: var(--spacing-3) 0 0 0;
}

.border-left-right:after,
.border-left-right:before {
  content: "";
  border-bottom: 1px solid black;
  border-bottom: 1px solid black;
  flex: 1 0 auto;
  height: 0.5em;
  margin: 0;
}

.border-left-right span {
  text-align: center;
  flex: 0.2 0 auto;
  margin: 0;
}

.required::after {
  content: "*";
  color: red;
  padding-left: 5px;
}




/* Admin Dashboard */

.cards-dashboard img {
  height: 150px;
  width: 100%;
}

.item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;


}

.cards-dashboard {
  border: 1px solid;
}

.item-cards-dashboard {
  transition: 0.5s;
  cursor: pointer;


}

.item-cards-dashboard-title {
  font-size: 15px;
  transition: 1s;
  cursor: pointer;
}

.item-cards-dashboard-title i {
  font-size: 15px;
  transition: 1s;
  cursor: pointer;
  color: #ffa710
}

.cards-dashboard-title i:hover {
  transform: scale(1.25) rotate(100deg);
  color: #18d4ca;

}

.cards-dashboard:hover {
  transform: scale(1.05);
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
}

.cards-dashboard-text {
  height: 80px;
}

.cards-dashboard::before,
.cards-dashboard::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale3d(0, 0, 1);
  transition: transform .3s ease-out 0s;
  background: rgba(255, 255, 255, 0.1);
  content: '';
  pointer-events: none;
}

.cards-dashboard::before {
  transform-origin: left top;
}

.cards-dashboard::after {
  transform-origin: right bottom;
}

.cards-dashboard:hover::before,
.cards-dashboard:hover::after,
.cards-dashboard:focus::before,
.cards-dashboard:focus::after {
  transform: scale3d(1, 1, 1);
}

.cards-dashboard-body {
  padding: 20px;
  /* background:#FFFF99 ; */
}



#collasible-nav-dropdown {
  background-color: darkblue;
  color: white;
}





.userName {
  color: blue;
  font-weight: bold;
  font-size: 18px;
  padding-top: 30px;
  padding-right: 50px;
}

.arrowDown {
  padding-top: 30px;
  padding-right: 50px;
}

.bs-example {
  margin: 20px;
}





/* Create Quiz */


.container-fluid-create {
  /* background-color: #D9E4EC; */
  padding: 20px;
}

.card-leftsidebody-create {
  padding: 30px;
  border-radius: 15px;
  /* background-color: azure; */
}

.card-rightsidebody-create {
  padding: 30px;
  justify-content: center;
  /* display: flex; */
  align-items: center;
  border-radius: 15px;
  /* background-color: azure; */
}

#QuizTitle {
  width: 100%;
  padding: 8px 16px;
  margin: 8px 0;
  box-sizing: border-box;
  /* border: 1px solid black; */
  border-radius: 4px;
  background-color: lightgray;
}

.Quiz-admin .form-control {
  width: 100%;
  padding: 8px 16px;
  margin: 8px 0;
  box-sizing: border-box;
  /* border: 1px solid black; */
  border-radius: 4px;
  background-color: lightgray
}

#Description {
  width: 100%;
  padding: 8px 16px;
  margin: 8px 0;
  box-sizing: border-box;
  /* border: 1px solid black; */
  border-radius: 4px;
  background-color: lightgray
}

#NoOfAttempts {
  width: 100%;
  padding: 8px 16px;
  margin: 8px 0;
  box-sizing: border-box;
  /* border: 1px solid black; */
  border-radius: 4px;
  background-color: lightgray
}

#TotalDuration {
  width: 100%;
  padding: 8px 16px;
  margin: 8px 0;
  box-sizing: border-box;
  /* border: 1px solid black; */
  border-radius: 4px;
  background-color: lightgray
}


#QuizSize {
  width: 100%;
  padding: 8px 16px;
  margin: 8px 0;
  box-sizing: border-box;
  /* border: 1px solid black; */
  border-radius: 4px;
  background-color: lightgray
}

#PassPercentage {
  width: 100%;
  padding: 8px 16px;
  margin: 8px 0;
  box-sizing: border-box;
  /* border: 1px solid black; */
  border-radius: 4px;
  background-color: lightgray
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  ;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #4154f1;
}

input:focus+.slider {
  box-shadow: 0 0 1px #4154f1;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;

}

.slider.round:before {
  border-radius: 50%;
}

.button-submit {
  border-radius: 15px;
  border: none;
  width: 200px;
  height: 50px;
  background-color: #76B947;
  color: white;
  font-size: 18px;
  font-weight: bold;
  float: right;
  margin: 15px;

}


.button-cancel {
  border-radius: 15px;
  border: none;
  width: 200px;
  height: 50px;
  background-color: red;
  color: white;
  font-size: 18px;
  font-weight: bold;
  float: right;
  margin: 15px;

}

.date-picker input {
  display: block;
  width: 100%;
  height: 2.5rem;
  padding: 0.875rem 1.375rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  color: black;
  background-color: lightgray;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 2px;
  /* transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */

}


.date-picker input:focus {
  border-color: skyblue;
  border: none;
  box-shadow: 0 0 0 0.3rem skyblue;
}


.can-toggle {
  position: relative;
}

.can-toggle *,
.can-toggle *:before,
.can-toggle *:after {
  box-sizing: border-box;
}

.can-toggle input[type=checkbox] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.can-toggle input[type=checkbox][disabled]~label {
  pointer-events: none;
}

.can-toggle input[type=checkbox][disabled]~label .can-toggle__switch {
  opacity: 0.4;
}

.can-toggle input[type=checkbox]:checked~label .can-toggle__switch:before {
  content: attr(data-unchecked);
  left: 0;
}

.can-toggle input[type=checkbox]:checked~label .can-toggle__switch:after {
  content: attr(data-checked);
}

.can-toggle label {
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
}

.can-toggle label .can-toggle__label-text {
  flex: 1;
  padding-left: 32px;
}

.can-toggle label .can-toggle__switch {
  position: relative;
}

.can-toggle label .can-toggle__switch:before {
  content: attr(data-checked);
  position: absolute;
  top: 0;
  text-transform: uppercase;
  text-align: center;
}

.can-toggle label .can-toggle__switch:after {
  content: attr(data-unchecked);
  position: absolute;
  z-index: 5;
  text-transform: uppercase;
  text-align: center;
  background: white;
  transform: translate3d(0, 0, 0);
}

.can-toggle input[type=checkbox][disabled]~label {
  color: rgba(234, 0, 48, 0.5);
}

.can-toggle input[type=checkbox]:focus~label .can-toggle__switch,
.can-toggle input[type=checkbox]:hover~label .can-toggle__switch {
  background-color: #ea0030;
}

.can-toggle input[type=checkbox]:focus~label .can-toggle__switch:after,
.can-toggle input[type=checkbox]:hover~label .can-toggle__switch:after {
  color: #b70026;
}

.can-toggle input[type=checkbox]:hover~label {
  color: #d1002b;
}

.can-toggle input[type=checkbox]:checked~label:hover {
  color: #55bc49;
}

.can-toggle input[type=checkbox]:checked~label .can-toggle__switch {
  background-color: #70c767;
}

.can-toggle input[type=checkbox]:checked~label .can-toggle__switch:after {
  color: #4fb743;
}

.can-toggle input[type=checkbox]:checked:focus~label .can-toggle__switch,
.can-toggle input[type=checkbox]:checked:hover~label .can-toggle__switch {
  background-color: #5fc054;
}

.can-toggle input[type=checkbox]:checked:focus~label .can-toggle__switch:after,
.can-toggle input[type=checkbox]:checked:hover~label .can-toggle__switch:after {
  color: #47a43d;
}

.can-toggle label .can-toggle__label-text {
  flex: 1;
}

.can-toggle label .can-toggle__switch {
  transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
  background: #ff0538;
}

.can-toggle label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.5);
}

.can-toggle label .can-toggle__switch:after {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  color: #ea0030;
}

.can-toggle input[type=checkbox]:focus~label .can-toggle__switch:after,
.can-toggle input[type=checkbox]:hover~label .can-toggle__switch:after {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}

.can-toggle input[type=checkbox]:checked~label .can-toggle__switch:after {
  transform: translate3d(65px, 0, 0);
}

.can-toggle input[type=checkbox]:checked:focus~label .can-toggle__switch:after,
.can-toggle input[type=checkbox]:checked:hover~label .can-toggle__switch:after {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}

.can-toggle label {
  font-size: 14px;
}

.can-toggle label .can-toggle__switch {
  height: 36px;
  flex: 0 0 134px;
  border-radius: 4px;
}

.can-toggle label .can-toggle__switch:before {
  left: 67px;
  font-size: 12px;
  line-height: 36px;
  width: 67px;
  padding: 0 12px;
}

.can-toggle label .can-toggle__switch:after {
  top: 2px;
  left: 2px;
  border-radius: 2px;
  width: 65px;
  line-height: 32px;
  font-size: 12px;
}

.can-toggle label .can-toggle__switch:hover:after {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}

.can-toggle.can-toggle--size-small input[type=checkbox]:focus~label .can-toggle__switch:after,
.can-toggle.can-toggle--size-small input[type=checkbox]:hover~label .can-toggle__switch:after {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

.can-toggle.can-toggle--size-small input[type=checkbox]:checked~label .can-toggle__switch:after {
  transform: translate3d(44px, 0, 0);
}

.can-toggle.can-toggle--size-small input[type=checkbox]:checked:focus~label .can-toggle__switch:after,
.can-toggle.can-toggle--size-small input[type=checkbox]:checked:hover~label .can-toggle__switch:after {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

.can-toggle.can-toggle--size-small label {
  font-size: 13px;
}

.can-toggle.can-toggle--size-small label .can-toggle__switch {
  height: 28px;
  flex: 0 0 90px;
  border-radius: 2px;
}

.can-toggle.can-toggle--size-small label .can-toggle__switch:before {
  left: 45px;
  font-size: 10px;
  line-height: 28px;
  width: 45px;
  padding: 0 12px;
}

.can-toggle.can-toggle--size-small label .can-toggle__switch:after {
  top: 1px;
  left: 1px;
  border-radius: 1px;
  width: 44px;
  line-height: 26px;
  font-size: 10px;
}

.can-toggle.can-toggle--size-small label .can-toggle__switch:hover:after {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

#inputAdaptive {
  width: 50%;
  padding: 8px 16px;
  margin: 8px 0;
  box-sizing: border-box;
  /* border: 1px solid black; */
  border-radius: 4px;
  background-color: lightgray
}


/* Single choice */

.card-side-body {
  /* padding: 60px; */
  display: flex;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 15px;
  /* background-color: azure; */

}

.card-leftside-body {
  /* padding: 40px; */
  padding: 20px;
  border-radius: 15px;
  /* background-color: azure; */

}


.single-choice .form-check .form-check-input {
  width: 20px;
  height: 20px;
  margin-top: 40px;
  /* border:1px solid black; */

}


.drop-area {
  height: 140px;
  width: 200px;
  background: #eee;
  border: 2px dashed blue;
  border-radius: 10px;
  display: grid;
  place-items: center;
  position: relative;
  padding: 10px;
}

.drop-area span {
  position: absolute;
  top: 60px;
  font-size: 17px;
}

.drop-area input {
  height: 100%;
  width: 100%;
  opacity: 0;
}

.drop-area img {
  width: 180px;
  height: 100px;
  margin: 5px;
}

.upload {
  width: 100%;
  margin-top: 10px;
}



.fileinfo {
  /* padding: 10px; */
  width: 200px;
  display: flex;
  justify-content: center;
  background: #fff;
  margin-top: 10px;
  border-radius: 10px;
  color: black;
}

.plusImage {
  width: 40px;
  height: 40px;
  float: right;
}

.selectWrapper {
  border-radius: 15px;
  display: inline-block;
  width: 175px;
  overflow: hidden;
  background: #4154f1;
  ;
  border: 1px wheat;
}

.selectBox {
  width: 155px;
  height: 50px;
  border: 0px;
  outline: none;
  background-color: #4154f1;
  ;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 15px;
}

/* select option {
        background: white;
        color: black;
        border-radius: 15px;        
    } */

.button-submit {
  border-radius: 15px;
  border: none;
  width: 200px;
  height: 50px;
  background-color: #76B947;
  color: white;
  font-size: 18px;
  font-weight: bold;
  float: right;
  margin: 15px;

}

.dropdown-toggle::after {
  display: none !important;
}

#options {
  background-color: lightgray;
  /* border: 2px solid black; */
}

.container-fluid-single {
  background-color: #D9E4EC;
  padding: 20px;
}



/* Create Question */

.radioImg {
  width: 30px;
  height: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
}

.cards-createquestion {
  background-color: lavender;
  border-radius: 7px;
  margin: 30px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

.cards-createquestion:hover {
  transform: scale(1.05);
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
  border-color: black;
}

.card-main-body {
  /* background: #F7F9A7; */
  /* padding-left: 40px; */
}

.container-createQuestion {
  background-color: #D9E4EC;
  padding: 20px;
}


.createSpan {
  font-size: 14px;
}


/* Quiz Response Css */

.flex-container {
  padding: 0;
  margin: 5;
  list-style: none;

  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

.longhand {
  -webkit-flex-flow: wrap row;
  flex-flow: wrap row;

}

.longhand li {
  background: #E5E4E2;
}

.flex-item {
  padding: 5px;
  width: 40px;
  height: 40px;
  margin: 5px;
  color: black;
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
  border: 1px solid #A9A9A9;
}

#radioQuizResponse {
  margin: 5px;
}

.card-body-Next {
  background-color: #007791;
  padding: 10px;
}

.btnNext {
  display: flex;
  justify-content: flex-end;
}

.timer {
  float: right;
  /* padding-right: 50px;
            padding-bottom: 5px; */
}

.error-userresponse {
  padding-bottom: 180px;
  padding-top: 90px;
}

/* User Dashboard Css */

/* .user-bg{
    background-color: #D9E4EC;
} */

/* .user-dashboard {
  background-color: #f6f9ff;
  padding: 20px;
} */

.wrapper-content {
  display: flex;
  width: 100%;
}

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  display: flex;
  width: 100%;
  padding-top: 10px;
  align-items: stretch;
}

#wrapper.toggled {
  padding-left: 250px;
}

#sidebar-wrapper {
  z-index: 1;
  /* position: absolute; */
  left: 250px;
  /* width: 0; */
  /* height: 100%; */
  height: auto;
  /* margin-left: -250px; */
  margin-left: -257px;
  overflow-y: auto;
  background: #fff;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#sidebar-wrapper {
  /* border: 1px solid #DDDDDD; */
  /* border-radius: 10px; */
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

#page-content-wrapper {
  width: 100%;
  /* padding: 20px; */
  min-height: 100vh;
  transition: all 0.3s;
}


#wrapper.toggled #page-content-wrapper {
  position: relative;
  margin-right: -250px;
}

/* Sidebar Css */

.sidebar-nav {
  /* position: absolute; */
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  text-indent: 10px;
  line-height: 40px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  /* color: #484848; */
  color: #012970;
  font-size: 14px;
  font-weight: bold;
  text-indent: 10px;
  line-height: 40px;
  transition: background-color 0.3s;
}



.sidebar-nav li a:hover {
  background-color: slateblue;
  color: #fff;
  border-radius: 35px;
}

/* Define different colors for menu items */
.sidebar-nav li.active a {
  background-color: slateblue;
  color: #fff;
  border-radius: 35px;
}

.sidebar-nav li i {
  margin-right: 10px;
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav>.sidebar-brand {
  height: 65px;
  font-size: 18px;
  line-height: 40px;
  color: #012970;
  font-weight: bold
}


.sidebar-nav>.sidebar-brand a {
  /* color:#484848; */
  color: #012970;
}

/* .sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none;
} */

@media(min-width:768px) {
  #wrapper {
    padding-left: 250px;
  }

  #wrapper.toggled {
    padding-left: 0;
  }

  #sidebar-wrapper {
    width: 250px;
    background: #f6f9ff;
    border-radius: 10px;
  }

  /* #wrapper.toggled #sidebar-wrapper {
    width: 0;
  } */

  #page-content-wrapper {
    /* padding: 20px; */
    position: relative;
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}


/* @media(max-width:767px) {
  #wrapper {
    padding-left: 250px;
  }

  #wrapper.toggled {
    padding-left: 0;
  }

  #sidebar-wrapper {
    width: 250px;
    background: #f6f9ff;
    border-radius: 10px;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 0;
  }

  #page-content-wrapper {
    
    position: relative;
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
} */

/* #sidebar-wrapper li.active>a:after {
  
  border-right: 17px solid #012970;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  right: -1px;
} */

.sidebar-brand {
  border-bottom: 1px solid rgba(102, 97, 91, 0.3);
}

.sidebar-brand {
  padding: 15px 32px;
  margin: 7px 20px;
}

.navbar .navbar-nav>li>a p {
  display: inline-block;
  margin: 0;
}

/* p {
  font-size: 16px;
  line-height: 1.4em;
} */

.navbar-default {
  /* background-color: #f4f3ef; */
  border: 0px;
  /* border-bottom: 1px solid #DDDDDD; */
}

btn-menu {
  border-radius: 3px;
  padding: 4px 12px;
  margin: 14px 5px 5px 20px;
  font-size: 14px;
  float: left;
}


.order-card {
  color: #fff;
}

.bg-c-card {
  background: slateblue;
}



.card-quiz {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  border: none;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.card-quiz .card-block {
  padding: 25px;
}

.card-quiz:hover {
  transform: translateY(-5px);
}

.order-card i {
  font-size: 26px;
}

.order-card h6 {
  font-size: 18px;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.btn-toggle-menu {
  background: #fff;
  /* Button background color */
  color: #012970;
  /* Button text color */
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-toggle-menu:hover {
  background: #012970;
  /* Button background color on hover */
  color: #fff;
  /* Button text color on hover */
  border-radius: 2px;
}



/* Error Page */

#notfound {
  position: relative;
  height: 100vh;
  background-color: #222;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 460px;
  width: 100%;
  text-align: center;
  line-height: 1.4;
}

.notfound .notfound-404 {
  height: 158px;
  line-height: 153px;
}

.notfound .notfound-404 h1 {
  font-family: 'Josefin Sans', sans-serif;
  color: #222;
  font-size: 220px;
  letter-spacing: 10px;
  margin: 0px;
  font-weight: 700;
  text-shadow: 2px 2px 0px #c9c9c9, -2px -2px 0px #c9c9c9;
}

.notfound .notfound-404 h1>span {
  text-shadow: 2px 2px 0px #ffab00, -2px -2px 0px #ffab00, 0px 0px 8px #ff8700;
}

.notfound p {
  font-family: 'Josefin Sans', sans-serif;
  color: #c9c9c9;
  font-size: 16px;
  font-weight: 400;
  margin-top: 74px;
  margin-bottom: 15px;
}

.notfound a {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: transparent;
  color: #c9c9c9;
  border: 2px solid #c9c9c9;
  display: inline-block;
  padding: 10px 25px;
  font-weight: 700;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound a:hover {
  color: #ffab00;
  border-color: #ffab00;
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 {
    height: 122px;
    line-height: 122px;
  }

  .notfound .notfound-404 h1 {
    font-size: 122px;
  }
}


.message {

  list-style-type: none;
  width: 100%;
  padding: 27px;
  background: rgb(237, 243, 253);
  font-size: 18px;
  line-height: 200%;
  text-align: center;

}

.message p {
  scroll-margin: 6rem;
  color: rgb(33, 38, 79);
  font-weight: 700;
  margin: 0px;
  padding: 0px;
  line-height: 1.25;
  letter-spacing: -0.03rem;
}


/* Attempted Quizzes cards in User Dashboard */

.card-attempted-quiz {
  max-width: 300px;
  min-height: 250px;
  /* background: #02b875; */
  padding: 30px;
  border-radius: 3px;
  box-sizing: border-box;
  color: #FFF;
  margin: 20px;
  box-shadow: rgb(50 50 93 / 25%) 0px 30px 60px -12px, rgb(0 0 0 / 30%) 0px 18px 36px -18px;
}

.card-attempted-quiz.first-card {
  background: #02b875;
}

.card-attempted-quiz.second-card {
  background: #4181ee;
}

.card-attempted-quiz.third-card {
  background: #673ab7;
}


.card-attempted-quiz.card-title {
  margin-top: 0;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.2px;

}

.card-attempted-quiz h3 {
  color: white;
}

.card-attempted-quiz.card-content {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 1.5;
}

.card-attempted-quiz .card-btn {
  all: unset;
  display: block;
  margin-left: auto;
  border: 2px solid #FFF;
  padding: 10px 15px;
  border-radius: 25px;
  font-size: 10px;
  font-weight: 600;
  transition: all 0.5s;
  cursor: pointer;
  letter-spacing: 1.2px;
}

.card-btn:hover {
  color: #02b875;
  background: #FFF;
}

.card-attempted-quiz:nth-child(2) .card-btn:hover {
  color: #4181ee;
  background: #FFF;
}

.card-attempted-quiz:last-child .card-btn:hover {
  color: #673ab7;
  background: #FFF;
}





.card-assigned-ctf .card-icon {
  margin: 0 1em;
}
.card-assigned-ctf .card-icon i {
  font-size: 3em;
}
.card-assigned-ctf .card {
  background-color: teal;
  color: #fff;
  padding: 3em 0;
  border: 0;
  /* border-radius: 4em 0; */
  /* background: linear-gradient(to left, teal 50%, black 50%); */
  background-size: 200%;
  background-position: right;
  transition: all 0.5s;
  margin: 5px;
}
.card-assigned-ctf .card:hover {
  background-position: left;
}




.card1 h3 {
  color: #262626;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 4px;
}

.card1 p {
  font-size: 13px;
  /* font-weight: 400; */
  line-height: 20px;
  color: #666666;

  &.small {
    /* font-size: 14px; */
  }
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: slateblue;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.card1 {
  display: block;
  position: relative;
  /* max-width: 262px; */
  background-color: #e1e2f2;
  border-radius: 4px;
  padding: 32px 17px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #00838d;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
  }

  /* &:hover:before {
    transform: scale(21);
  } */
}

/* .card1:hover {
  p {
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);
  }
  h3 {
    transition: all 0.3s ease-out;
    color: #ffffff;
  }
} */


.card1 .text-crimson{
  color:crimson;
}


.card1 .text-courseName{
  color:steelblue;
}







.services-assignedchallenge .icon-box {
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  padding:20px;
  width: 200px ;
  height: 172px;
  transition: all ease-in-out 0.4s;
  background: #fff;
}


.services-assignedchallenge .icon-box h3{
  color: #262626;
  font-size: 13px;
  line-height: 24px;
  font-weight: 700;
  /* margin-bottom: 4px; */
}




.services-assignedchallenge .icon-box p {
  line-height: 24px;
  font-size: 13px;
  margin-bottom: 0;
}

.services-assignedchallenge .icon-box:hover {
  transform: translateY(-10px);
}

.services-assignedchallenge .icon-box:hover h4 a {
  color: #47b2e4;
}




/* Example custom styles */
.custom-nav {
    background-color: #f0f0f0;
    border-radius: 8px;
    /* Add more styles as needed */
}

.nav-link-custom {
    color: #555;
    font-weight: bold;
    /* Add more styles as needed */
}

.custom-tab-content {
    /* Add styles for tab content */
    padding: 20px;
    border: 1px solid #ccc;
    margin-bottom: 30px;
    border-radius: 8px;
    /* Add more styles as needed */
}


.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff !important;
    background-color: #0d6efd !important;
}

.nav-pills .nav-link-custom.active {
    background-color: #4154f1 !important;
    color: #fff !important;
}


.details .content+.content {
  margin-top: 100px;
}

.details .content h3 {
  font-weight: 600;
  font-size: 26px;
  color: #010483;
}

.details .content ul {
  list-style: none;
  padding: 0;
}

.details .content ul li {
  padding-bottom: 10px;
}

.details .content ul i {
  font-size: 18px;
  padding-right: 4px;
  color: #010483;
}

.details .content p:last-child {
  margin-bottom: 0;
}

.details .content span{
  color: #4154f1;
  font-weight: 600;
}


/* .details img{
  margin-top:90px;
} */



.main-image{
  margin-top :91px;
}

.carousel-indicators .active {
  width: 12px; /* Change width of active dot */
  height: 12px; /* Change height of active dot */
  border-radius: 50%; /* Ensure the dot is circular */
  background-color: black; /* Change YourActiveColor to the desired active color */
}

/* Styling for inactive indicators */
.carousel-indicators [data-bs-target] {
  width: 10px; /* Change width of inactive dot */
  height: 10px; /* Change height of inactive dot */
  border-radius: 50%; /* Ensure the dot is circular */
  background-color: grey; /* Change YourInactiveColor to the desired inactive color */
  /* Additional styling for inactive indicators */
}

/* Custom CSS file or add this to your existing CSS file */
/* Adjust the position of indicators */
.carousel-indicators {
  bottom: -65px; /* Adjust this value to change the distance from the bottom */
  

}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%; /* Ensure the Carousel images take full width */
}

.carousel-item {
  position: relative;
}

.carousel-caption-bottom {
  position: relative;
  bottom: -10px;
  left: 0;
  right: 0;
  padding: 20px;
  color: black;
}


.content img {
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  margin-bottom: 20px;
}


.border-header{
  border-left: 1.5px solid gray;
  height: 60px;
  margin: 0 10px;
  opacity: 0.5;
}

.btn-rewards{
  line-height: 0;
  /* padding: 15px 40px; */
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background:slateblue;
  box-shadow: 0px 5px 25px rgb(65 84 241 / 30%);
}


@media (max-width: 768px) {
  .val-certificate {
      font-size: 1rem;
  }
  .cert-value {
      font-size: 0.8rem;
  }
}



.wrapper-live-quiz-user{
  background-image: url('../../assets/img/bg-image-user.jpg'); 
  background-size: cover;
  position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;

}



.start-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px 30px;
  font-size: 16px;
  width: auto; 
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .start-button {
      padding: 10px 20px;
      font-size: 23px;
      font-weight: 500;
      width: 100%; /* Set width to 100% on smaller screens */
      max-width: 300px; /* Set max-width to limit expansion on very small screens */
  }
}


.dash {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #013289;
  white-space: nowrap;
  transition: 0.3s;
}